<template>
  <component :is="componentType" :to="componentType === 'a' ? undefined : props.href" :href="componentType === 'a' ? props.href : undefined" :rel="props.linkRel || ((componentType === 'a' && (props.href?.startsWith('tel:') || props.href?.startsWith('mailto:'))) ? 'nofollow' : undefined)" :target="props.target" @click="click" class="button" :class="[props.class, { 'button--secondary': props.secondary, 'button--secondary-ghost': props.secondaryGhost, 'button--black': props.black, 'button--white-ghost': props.whiteGhost, 'button--ghost': props.ghost, 'button--small': props.small, 'cursor-wait' : props.showLoader }]">
    <Transition>
      <div v-if="_showLoader" class="button__loader">
        <AtomsGlobalLoader />
      </div>
    </Transition>
    <AtomsImagesIcon v-if="props.arrowLeft" icon="arrow-left" class="button__arrow button__arrow--left" :class="props.iconClass" />
    <AtomsImagesIcon v-if="props.icon" :icon="props.icon" class="mr-4" :class="props.iconClass" />
    <div class="flex-1">
      <slot></slot>
    </div>
    <AtomsImagesIcon v-if="props.iconRight" :icon="props.iconRight" class="ml-4" :class="props.iconClass" />
    <AtomsImagesIcon v-if="props.arrowRight" icon="arrow-right" class="button__arrow button__arrow--right" :class="props.iconClass" />
  </component>
</template>
<script setup>

const props = defineProps({
  type: String,
  href: String,
  target: String,
  dummy: Boolean,
  forceReload: Boolean,
  icon: String,
  iconRight: String,
  iconClass: String,
  arrowLeft: Boolean,
  arrowRight: Boolean,
  ghost: Boolean,
  small: Boolean,
  secondary: Boolean,
  black: Boolean,
  whiteGhost: Boolean,
  secondaryGhost: Boolean,
  class: String,
  showLoader: Boolean,
  linkRel: String
});

const emits = defineEmits(['click']);

const _showLoader = ref(false);

const click = (e) => {

  if (props.dummy) {
    return;
  }

  if (props.showLoader) {
    return;
  }

  if ((props.href && props.forceReload) || props.href?.startsWith('tel:') || props.href?.startsWith('mailto:')) {
    document.location = props.href;
  }
  else if (props.target) {
    window.open(props.href, '_blank');
  }

  emits('click');

  e.preventDefault();
}

const componentType = computed(() => {
  if (props.href) {
    if ((props.href.startsWith('http') && props.target === '_blank') || props.href.startsWith('tel:') || props.href.startsWith('mailto:')) {
      return 'a';
    }
    else {
      return resolveComponent('NuxtLink');
    }
  }
  else if (props.dummy) {
    return 'span';
  }
  else {
    return 'button';
  }
});

let showLoaderTimeout;

watch(() => props.showLoader, () => {
  if (!props.showLoader) {
    _showLoader.value = false;
    clearTimeout(showLoaderTimeout);
  }
  else if (!_showLoader.value) {
    showLoaderTimeout = setTimeout(() => _showLoader.value = true, 200);
  }
});

</script>
<style lang="postcss">
.button {

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  &__arrow {
    display: inline-block;
    font-weight: normal;
    transition: all ease-in-out 0.15s;
  }

  &__arrow--left {
    @apply mr-3 ml-0 tablet:mr-4;
  }

  &__arrow--right {
    @apply ml-3 mr-0 tablet:ml-4;
  }

  &:hover .button__arrow--left {
    transform: translateX(-5px);
  }

  &:hover .button__arrow--right {
    transform: translateX(5px);
  }

  &__loader {
    @apply absolute left-0 top-0 right-0 bottom-0 z-10 flex items-center justify-center bg-primary-500 transition-all;
  }
}
</style>